<template>
  <div class="first-page" id="first">
    <div
      class="bg"
      :style="{ transform: `translateX(-50%) scaleX(${scaleX})` }"
    ></div>
    <div class="wrapper">
      <div class="content">
        <div class="title">
          Enhancing connections between ledgers by providing interoperability in
          Web 3.0
        </div>
        <div class="data">
          <div class="item">
            <div class="val">${{ numFormatter(ttv) }}</div>
            <div class="text">Total Volume</div>
          </div>
          <div class="item">
            <div class="val">${{ numFormatter(tvl) }}</div>
            <div class="text">Total Value Locked (TVL)</div>
          </div>
          <div class="item">
            <div class="val">{{ toFinancialVal(ttx) }}</div>
            <div class="text">Cross-chain Transactions</div>
          </div>
          <div class="item">
            <div class="val">{{ toFinancialVal(td) }}</div>
            <div class="text">Cross-chain Addresses</div>
          </div>
        </div>
      </div>
      <div class="img loaderPill-anim-bounce">
        <img src="../../assets/image/poly-3d-logo.png" width="326" />
      </div>
      <div class="img2">
        <transition name="fade">
          <img :src="img" width="392" />
        </transition>
      </div>
      <!-- <banner></banner> -->
    </div>
  </div>
</template>

<script>
// import Banner from "@/components/banner";
import tools from "../../utils/tools";
import axios from "axios";

export default {
  name: "HelloWorld",
  components: {
    // Banner,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      ttv: "14964841572",
      tvl: "1069204841",
      ttx: "683,877",
      td: "282,813",
      img1: require("../../assets/image/poly-3d-logo-b-2.png"),
      img2: require("../../assets/image/poly-3d-logo-b.png"),
      flag: false,
      scaleX: 1,
    };
  },
  computed: {
    img() {
      return this.flag ? this.img1 : this.img2;
    },
  },
  mounted() {
    this.init();
    this.timer = setInterval(() => {
      this.flag = !this.flag;
    }, 1600);
    this.myEventHandler();
    window.addEventListener("resize", this.myEventHandler);
  },
  beforeUnmount() {
    this.timer && clearInterval(this.timer);
    window.removeEventListener("resize", this.myEventHandler);
  },
  methods: {
    myEventHandler() {
      if (window.innerWidth > 1440) {
        this.scaleX = window.innerWidth / 1440;
      } else {
        this.scaleX = 1;
      }
    },
    init() {
      this.getData();
    },
    toFinancialVal($val) {
      return tools.HelperTools.toFinancialVal($val);
    },
    numFormatter($val) {
      return tools.HelperTools.numFormatter($val);
    },
    async getData() {
      let url1 = "https://explorer.poly.network/api/v1/getexplorerinfo";
      let res1 = await axios({ method: "get", url: url1 });
      this.ttx = res1.data.crosstxnumber;

      let url2 = "https://explorer.poly.network/api/v1/getassetstatistic";
      let res2 = await axios({ method: "get", url: url2 });
      this.ttv = res2.data.amount_usd_total;

      let url3 = "https://explorer.poly.network/api/v1/getlocktokenlist";
      let res3 = await axios({ method: "get", url: url3 });
      let tvl = 0;
      for (let i = 0; i < res3.data.length; i++) {
        tvl = tvl + Number(res3.data[i].amountUsd);
      }
      this.tvl = tvl;

      let url4 =
        "https://explorer.poly.network/api/v1/gettransferstatistic?chain=0";
      let res4 = await axios({ method: "get", url: url4 });
      this.td = res4.data.addresses;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.first-page {
  // background: conic-gradient(
  //   from -88.84deg at 50% 50.97%,
  //   #04061f 0deg,
  //   #10103c 50.95deg,
  //   #141b64 82.75deg,
  //   #1d79e6 109.35deg,
  //   #1fd7ff 133.87deg,
  //   #00ffe1 156.24deg,
  //   #0fceda 183.59deg,
  //   #067eee 201.23deg,
  //   #050826 230.69deg,
  //   #04061f 283.41deg,
  //   #04061f 360deg
  // );
  // backdrop-filter: blur(1000px);
  // background-image: url("../../assets/image/bg.png");
  .bg {
    content: " ";
    position: absolute;
    height: 100%;
    background-image: conic-gradient(
      from 269.4deg at 49.55% 52.49%,
      #04061f 0deg,
      #10103c 50.38deg,
      #141b64 79.59deg,
      #1e7ff0 113.86deg,
      #00c6f1 135.16deg,
      rgba(0, 255, 255, 0.95) 156.19deg,
      #10e7f4 180.62deg,
      #0085ff 203.89deg,
      #030733 220.86deg,
      #04061f 267.62deg,
      #04061f 360deg
    );
    background-size: 100%;
    background-position: center;
    width: 1440px;
    max-width: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  display: flex;
  position: relative;
  background-repeat: no-repeat;
  min-height: 100vh;
  .wrapper {
    max-width: 1920px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }
  .content {
    display: flex;
    flex-direction: column;
    padding: 274px 80px 0;
    align-items: flex-start;
    z-index: 10;
    position: relative;

    .title {
      max-width: 900px;
      margin-bottom: 120px;
      font-family: Samsung Sharp Sans Blod;
      font-style: normal;
      font-weight: bold;
      font-size: 60px;
      line-height: 85px;
      color: #fff;
      text-align: left;
    }
    .data {
      display: flex;
      width: 860px;
      justify-content: space-between;
      .item {
        .val {
          font-family: DINPro-Bold;
          font-style: normal;
          font-weight: bold;
          font-size: 48px;
          line-height: 64px;
          /* identical to box height, or 133% */

          text-align: center;

          /* 撞色橙 */

          color: #eba50a;
        }
        .text {
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          /* identical to box height, or 150% */

          text-align: center;

          color: rgba(255, 255, 255, 0.8);
        }
      }
    }
  }
  .img {
    position: absolute;
    right: 80px;
    top: 348px;
    z-index: 11;
  }
  .img2 {
    position: absolute;
    right: 60px;
    top: 550px;
    z-index: 10;
  }
}
</style>

<style scoped>
.loaderPill-anim-bounce {
  animation: bounce 1600ms ease-in infinite alternate;
}

@keyframes bounce {
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(10px);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
