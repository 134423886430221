<template>
  <div
    class="why"
    id="why"
    :class="{ inView: inView }"
    v-observe-visibility="{
      callback: inViewChanged,
      once: true,
      intersection: {
        threshold: 0.1,
      },
    }"
  >
    <div class="title">Why Poly Network</div>
    <div class="content">
      <div v-for="(item, index) in list" :key="index" class="item">
        <div class="img">
          <img :src="item.url" />
        </div>
        <div class="text">{{ item.text }}</div>
      </div>
    </div>
    <img class="b1" src="../../assets/image/whyb1.svg" />
    <img class="b2" src="../../assets/image/whyb2.svg" />
    <img class="b3" src="../../assets/image/whyb3.svg" />
  </div>
</template>

<script>
export default {
  name: "why",
  props: {
    msg: String,
  },
  data() {
    return {
      list: [
        {
          text: "Light weight",
          url: require("../../assets/image/why1.svg"),
        },
        {
          text: "Loosely coupled",
          url: require("../../assets/image/why2.svg"),
        },
        {
          text: "Easy integration",
          url: require("../../assets/image/why3.svg"),
        },
        {
          text: "High security",
          url: require("../../assets/image/why4.svg"),
        },
        {
          text: "Interoperability",
          url: require("../../assets/image/why5.svg"),
        },
        {
          text: "High speed",
          url: require("../../assets/image/why6.svg"),
        },
        {
          text: "Low cost",
          url: require("../../assets/image/why7.svg"),
        },
        {
          text: "Cross-chain asset transfer",
          url: require("../../assets/image/why8.svg"),
        },
        {
          text: "NFT cross-chain transfer",
          url: require("../../assets/image/why9.svg"),
        },
        {
          text: "Simple Swap",
          url: require("../../assets/image/why10.svg"),
        },
      ],
      inView: false,
    };
  },
  methods: {
    inViewChanged(inView) {
      this.inView = inView;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.why {
  width: 960px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 220px;
  position: relative;
  box-sizing: border-box;
  transform: translateY(60px);

  &.inView {
    animation: show 0.8s ease;
    animation-fill-mode: forwards;
    animation-delay: 100ms;
  }
  .b1 {
    position: absolute;
    left: 0;
    top: 0;
  }
  .b2 {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .b3 {
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .title {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
    line-height: 64px;
    /* identical to box height, or 142% */
    letter-spacing: 2.5px;
    mix-blend-mode: normal;
    color: #ffffff;
    text-align: left;
    margin-bottom: 100px;
    z-index: 1;
    position: relative;
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    z-index: 1;
    position: relative;
    .item {
      width: 20%;
      min-width: 180px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 80px;
      &:hover .img {
        transform: translateY(-5px);
        box-shadow: 0px 3.0185184478759766px 3.1481480598449707px 0px #095578,
          0px 13.281481742858887px 6.518518447875977px 0px #09557804,
          0px 32.599998474121094px 13px 0px #09557805,
          0px 62.785186767578125px 25.481481552124023px 0px #09557806,
          0px 105.64814758300781px 46.85185241699219px 0px #09557808,
          0px 163px 80px 0px #0955780a;
      }
      .img {
        width: 100px;
        height: 100px;
        border-radius: 50px;
        border: 1px solid rgba(255, 255, 255, 0.25);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        transition: all 0.3s cubic-bezier(0.21, 0.6, 0.35, 1);
        img {
          width: 50px;
        }
      }
      .text {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 31px;
        /* or 155% */

        text-align: center;

        /* 1-why poly describe */

        color: #f1f1f1;
      }
    }
  }
}

@keyframes show {
  0% {
    pointer-events: none;
    visibility: visible;
    opacity: 0;
    transform: translateY(60px);
  }
  100% {
    pointer-events: inherit;
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
