<template>
  <div
    class="partners"
    id="partners"
    :class="{ inView: inView }"
    v-observe-visibility="{
      callback: inViewChanged,
      once: true,
      intersection: {
        threshold: 0.1,
      },
    }"
  >
    <div class="title">Partners</div>
    <ul class="tabs">
      <li
        v-for="item in ['Infrastructure', 'Defi', 'DEX', 'Tools', 'NFT']"
        :key="item"
        @click="selected(item)"
        class="tab"
        :class="{ selected: item === selectedKey }"
      >
        {{ item }}
      </li>
    </ul>
    <div class="img-wrapper">
      <ul class="content">
        <li v-for="item in selectedPartners" :key="item" class="img">
          <img
            :src="item.url"
            :width="item.width"
            class="img-rendering"
            loading="lazy"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
const w = {
  Infrastructure: [
    133.33, 139, 78, 119.19, 131.16, 134.33, 96, 138.23, 103.92, 145, 126, 124,
    125, 128.19, 97.87, 83.74, 145, 146, 76, 94, 103, 105, 154, 133.75, 128,
    150, 86, 97.25, 118, 136.74, 77, 125, 110, 115.69, 98.69, 138, 149
  ],
  Defi: [
    136, 170, 101, 133, 115, 114, 108, 110, 101, 107, 125, 113, 110, 140, 120,
    127, 105, 100, 120, 134, 100, 90, 95, 116, 94, 90, 91, 102, 80, 100, 99, 85,
    98.82, 71, 67, 80, 99, 110, 96, 176, 117, 106, 114, 124, 92, 122, 100, 79,
    131, 83, 121, 124.55, 99, 84, 140, 137,  98.02, 142
  ],
  NFT: [
    150, 64, 150, 120, 150, 110, 128, 140, 150, 121, 105, 115, 128, 150, 121,
    136, 156, 128, 165, 101, 110,
  ],
  DEX: [130, 130, 115, 130, 93.5, 131, 109, 150],
  Tools: [
    150, 175, 193, 155, 145, 162, 180, 109, 120, 154.24, 126.83, 117, 149.47,
    164.69, 174.78
  ],
};
export default {
  name: "partners",
  components: {},
  props: {
    msg: String,
  },
  computed: {
    selectedPartners() {
      if (this.selectedKey !== "Infrastructure") {
        return this.partners[this.selectedKey];
      }
      return this.partners[this.selectedKey].filter(
        (item, index) => index !== 20
      );
    },
  },
  data() {
    return {
      partners: {
        Infrastructure: new Array(w.Infrastructure.length)
          .fill(0)
          .map((_, index) => ({
            width: w.Infrastructure[index],
            url: require(`../../assets/image/partner/p1/${index + 1}.png`),
          })).filter((_, index) => ![2, 28].includes(index)),
        Defi: new Array(w.Defi.length)
          .fill(0)
          .map((_, index) => {
            return {
              width: w.Defi[index],
              url: require(`../../assets/image/partner/p2/${index + 1}.png`),
            };
          })
          .filter((_, index) => index !== 16),
        NFT: new Array(w.NFT.length).fill(0).map((_, index) => ({
          width: w.NFT[index],
          url: require(`../../assets/image/partner/p3/${index + 1}.png`),
        })),
        DEX: new Array(w.DEX.length).fill(0).map((_, index) => ({
          width: w.DEX[index],
          url: require(`../../assets/image/partner/p4/${index + 2}.png`),
        })),
        Tools: new Array(w.Tools.length).fill(0).map((_, index) => ({
          width: w.Tools[index],
          url: require(`../../assets/image/partner/p5/${index + 1}.png`),
        })),
      },
      selectedKey: "Infrastructure",
      inView: false,
    };
  },
  methods: {
    selected(key) {
      if (this.selectedKey === key) return;
      this.selectedKey = key;
    },
    inViewChanged(inView) {
      this.inView = inView;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.partners {
  width: 960px;
  margin-top: 220px;
  margin-bottom: 200px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;

  transform: translateY(60px);

  &.inView {
    animation: show 0.8s ease;
    animation-fill-mode: forwards;
    animation-delay: 100ms;
  }

  .title {
    font-family: Samsung Sharp Sans Blod;
    font-style: normal;
    font-weight: bold;
    font-size: 45px;
    line-height: 64px;
    /* identical to box height, or 142% */

    text-align: center;

    color: #ffffff;
    text-align: left;
  }

  .img-wrapper {
    height: 296px;
    box-sizing: border-box;
    overflow-y: auto;
    background: rgba(255, 255, 255, 0.05);
    mix-blend-mode: normal;
    border: 1px solid rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(46px);
    border-radius: 15px;
    /* width */
    &::-webkit-scrollbar {
      width: 15px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.16);
      border-radius: 8px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(255, 255, 255, 0.3);
    }
  }
  .content {
    z-index: 2;
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
    grid-row-gap: 45px;
    align-content: space-around;
    justify-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    box-sizing: border-box;
  }
  .tabs {
    margin-top: 56px;
    margin-bottom: 40px;
    text-align: center;
  }
  .tab {
    height: 44px;
    line-height: 44px;
    width: 151px;
    background-color: rgba(235, 235, 235, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 6px;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    font-feature-settings: "salt" on, "liga" off;
    color: #b6b6b6;
    text-align: center;
    cursor: pointer;
    display: inline-block;
    transition: background-color ease 0.3s;

    &.selected {
      background-color: #ebebeb;
      color: #0b112b;
      cursor: default;
    }
  }

  .img {
    transition: all 0.4s cubic-bezier(0.21, 0.6, 0.35, 1);
    width: 200px;
    min-height: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    .img-rendering {
      image-rendering: -moz-crisp-edges;
      image-rendering: -o-crisp-edges;
      image-rendering: -webkit-optimize-contrast;
      image-rendering: crisp-edges;
      -ms-interpolation-mode: nearest-neighbor;
      -webkit-font-smooting: antialiased;
    }

    &:hover img {
      opacity: 0.8;
    }
  }
}

@keyframes show {
  0% {
    pointer-events: none;
    visibility: visible;
    opacity: 0;
    transform: translateY(60px);
  }
  100% {
    pointer-events: inherit;
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
