<template>
  <div
    class="who"
    id="who"
    :class="{ inView: inView }"
    v-observe-visibility="{
      callback: inViewChanged,
      once: true,
      intersection: {
        threshold: 0.1,
      },
    }"
  >
    <div class="title">Who We Are</div>
    <div class="content">
      <div class="icon">
        <img src="../../assets/image/quotation.png" width="68" height="58" />
      </div>
      <div class="text">
        Poly Network is a global cross-chain protocol for implementing blockchain interoperability and building Web3.0 infrastructure. Poly Network has connected a variety of over 35 different blockchains, including popular ones such as Ethereum, Polygon, Arbitrum, and BNB Chain, as well as others such as Aptos, Optimism, Neo, Metis, and Gnosis Chain. Since the launch, the protocol has enabled cross-chain asset transfer of more than $16 billion USD.      </div>
      <img
        src="../../assets/image/who1.png"
        width="234"
        height="197"
        class="r-img"
      />
    </div>
    <img class="back" src="../../assets/image/whoback1.svg" />
  </div>
</template>

<script>
export default {
  name: "who",
  props: {
    msg: String,
  },
  data() {
    return {
      inView: false,
    };
  },
  methods: {
    inViewChanged(inView) {
      this.inView = inView;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.who {
  width: 960px;
  margin-top: -200px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  box-sizing: border-box;
  opacity: 0;
  transform: translateY(60px);

  &.inView {
    animation: show 0.8s ease;
    animation-fill-mode: forwards;
    animation-delay: 100ms;
  }

  .back {
    width: 123px;
    position: absolute;
    top: -60px;
    right: 100px;
  }
  .title {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
    line-height: 64px;
    /* identical to box height, or 142% */
    letter-spacing: 2.5px;
    mix-blend-mode: normal;
    color: #ffffff;
    text-align: left;
    margin-bottom: 50px;
  }
  .content {
    display: flex;
    justify-content: space-between;
    .icon {
      /* or 31% */
      margin-top: 15px;
      margin-right: 50px;
    }
    .text {
      width: 617px;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: #f1f1f1;
      text-align: left;
    }
  }
}

.r-img {
  align-self: flex-end;
  transform: translateY(30px);
}
@keyframes show {
  0% {
    pointer-events: none;
    visibility: visible;
    opacity: 0;
    transform: translateY(60px);
  }
  100% {
    pointer-events: inherit;
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
