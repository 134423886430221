<template>
  <div class="head">
    <div class="logo">
      <router-link to="/">
        <img src="../../assets/image/headlogo.svg" width="173" height="49" />
      </router-link>
    </div>
    <div class="nav">
      <div
        v-for="(item, index) in navData"
        :key="index"
        class="item"
        :class="{ 'item-active': item.routerName === activeNav }"
        @click="clickHandler(item)"
      >
        <span></span>{{ item.label }}
        <div class="sub-nav" v-if="item.subNav">
          <div class="arrow-wrap"><i class="arrow"></i></div>
          <ul>
            <li
              v-for="(item, index) in item.subNav"
              :key="index"
              @click.stop="open(item.url)"
              :class="item.class"
            >
              <div class="dot"><div></div></div>
                {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  computed: {
    activeNav() {
      return this.$route.name;
    },
  },
  created() {
    this.navData[1].subNav.unshift({
      name: "Poly Bridge",
      url: "https://bridge.poly.network",
    });
  },
  data() {
    return {
      navData: [
        {
          label: "Home",
          routerName: "Home",
        },
        {
          label: "dApps",
          subNav: [
            {
              name: "MDEX",
              url: "https://mdex.com",
            },
            {
              name: "O3 Swap",
              url: "https://v2.o3swap.com",
            },
            {
              name: "ZilBridge",
              url: "https://zilswap.io/bridge",
            },
            {
              name: "ChainSwap",
              url: "https://chainswap.com",
            },
            {
              name: "Flamingo",
              url: "https://flamingo.finance",
            },
            {
              name: "Wing",
              url: "https://wing.finance",
            },
            {
              name: "Demex",
              url: "https://dem.exchange",
            },
            {
              name: "NFT Shuttle",
              url: "https://nftshuttle.xyz/",
            },
          ].sort(function (a, b) {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          }),
        },
        {
          label: "Explorer",
          url: "https://explorer.poly.network/",
        },
        {
          label: "Docs",
          subNav: [
            {
              name: "White Paper",
              url: `${location.protocol}//${location.host}/PolyNetwork-whitepaper.pdf`,
            },
            {
              name: "Github",
              url: "https://github.com/polynetwork",
            },
            {
              name: "Guides",
              url: "https://medium.com/poly-network/tagged/tutorial",
            },
            {
              name: "Bounties",
              url: "https://immunefi.com/bounty/polynetwork/",
            },
            {
              name: "Audits",
              url: "https://github.com/polynetwork/audit-report",
            },
            {
              name: "Research",
              url: `${location.protocol}//${location.host}/ieee.pdf`,
            },
          ],
        },
        {
          label: "Developers",
          url: "https://dev-docs.poly.network/",
        },
        {
          label: "Career",
          routerName: "Career",
        },
      ],
    };
  },
  methods: {
    clickHandler(item) {
      if (item.routerName) {
        this.$router.push({ name: item.routerName });
      } else {
        if (item.url) {
          this.open(item.url);
        }
      }
    },
    open(url) {
      window.open(url);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.arrow-wrap {
  text-align: center;
  height: 13px;
}
.arrow {
  content: "";
  width: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-bottom: 8px solid rgba(52, 52, 52, 0.7);
  display: inline-block;
  transform: translateY(-50%);
}
.nav ul {
  background: #1c3e7c;
  backdrop-filter: blur(60px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  li:first-child {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  li:last-child {
    border-end-start-radius: 15px;
    border-end-end-radius: 15px;
  }
  li{
    cursor: pointer;
    margin: 0;
    padding: 12px 25px;
    transition: all ease 0.3s;
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    color: #ffffff;
    display: flex;
    a {
      text-decoration: none;
          color: #ffffff;
    }
    .dot {
      width: 10px;
      height: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      div {
        width: 5px;
        height: 5px;
        border-radius: 2.5px;
        background-color: #fff;
      }
    }
  }
  li:hover {
    background: #1a5598;
    transition: all ease 0.3s;
    .dot {
      width: 10px;
      div {
        width: 8px;
        height: 8px;
        border-radius: 4px;
      }
    }
  }
}

.head {
  position: absolute;
  width: 100%;
  height: 120px;
  top: 0px;
  display: flex;
  justify-content: space-between;
  padding: 98px 96px 0px 76px;
  box-sizing: border-box;
  z-index: 100;
  align-items: center;
  .nav {
    display: flex;
    .item {
      cursor: pointer;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      height: 24px;
      color: rgba(255, 255, 255, 0.7);
      transition: all ease 0.3s;
      padding: 0 35px;
      position: relative;
      img {
        opacity: 0.7;
      }
      > span {
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0);
        left: 15px;
        top: 8px;
        transition: all ease 0.3s;
      }
      .sub-nav {
        display: none;
      }
    }
    .item:hover,
    .item-active.item {
      color: rgba(255, 255, 255, 1);
      transition: all ease 0.3s;
      img {
        opacity: 1;
      }
      span {
        background-color: rgba(255, 255, 255, 1);
        transition: all ease 0.3s;
      }
    }

    .item:hover .sub-nav {
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 24px;
    }
  }
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
