<template>
  <head-nav></head-nav>
  <router-view />
  <foot-nav></foot-nav>
</template>
<script>
import HeadNav from "@/components/common/head";
import FootNav from "@/components/common/foot";

export default {
  components: {
    HeadNav,
    FootNav,
  },
  name: "Home",
  props: {
    msg: String,
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
  background-color: #04061f;
}
body {
  margin: 0;
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
