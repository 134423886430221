<template>
  <div class="side" id="side">
    <div
      :class="this.activeList[0] ? 'page-p page-active' : 'page-p'"
      @click="toPage(0)"
    ></div>
    <div class="dot" v-for="(index, item) in list" :key="index">{{ item }}</div>
    <div
      :class="this.activeList[1] ? 'page-p page-active' : 'page-p'"
      @click="toPage(1)"
    ></div>
    <div class="dot" v-for="(index, item) in list" :key="index">{{ item }}</div>
    <div
      :class="this.activeList[2] ? 'page-p page-active' : 'page-p'"
      @click="toPage(2)"
    ></div>
    <!-- <div class="dot" v-for="(index, item) in list" :key="index">{{ item }}</div>
    <div
      :class="this.activeList[3] ? 'page-p page-active' : 'page-p'"
      @click="toPage(3)"
    ></div> -->
  </div>
</template>

<script>
export default {
  name: "side",
  mounted() {
    function throttle(callback, limit) {
      let waiting = false;
      return function () {
        if (!waiting) {
          callback.apply(this, arguments);
          waiting = true;
          setTimeout(function () {
            waiting = false;
          }, limit);
        }
      };
    }
    this.scrollHandler = throttle(this.appScroll.bind(this), 200);
    document.addEventListener("scroll", this.scrollHandler);
    this.appScroll();
  },
  beforeUnmount() {
    document.removeEventListener("scroll", this.scrollHandler);
  },
  data() {
    return {
      list: ["", "", "", ""],
      activeList: [true, false, false],
    };
  },
  methods: {
    getOffsetTop(id) {
      const rect = document.getElementById(id).getBoundingClientRect();
      return rect.top;
    },
    getPos() {
      const pos = [
        this.getOffsetTop("who"),
        this.getOffsetTop("why") - 80,
        this.getOffsetTop("partners") - 160,
      ];
      return pos;
    },
    appScroll() {
      const t = this.getOffsetTop("side");
      const pos = this.getPos();

      let index = -1;
      for (let i = pos.length - 1; i >= 0; i--) {
        if (t >= pos[i]) {
          index = i;
          break;
        }
      }
      if (index === -1) return;
      this.activeList = this.activeList.map((_, i) => {
        if (i === index) {
          return true;
        }
        return false;
      });
    },
    toPage(index) {
      console.log(index)
      const ids = ["who", "why", "partners"];
      document
        .getElementById(ids[index])
        .scrollIntoView({ behavior: "smooth", block: 'center' });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.side {
  position: sticky;
  width: 60px;
  height: 160px;
  top: calc(50% - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 45px;
  z-index: 2;
  .page-p {
    margin-top: 6px;
    margin-bottom: 6px;
    height: 10px;
    width: 10px;
    border-radius: 5px;
    margin: 5px;
    background-color: #0a69ac;
    cursor: pointer;
    transition: all ease 0.3s;
  }
  .page-active {
    margin-top: 1px;
    margin-bottom: 1px;
    height: 22px;
    width: 22px;
    border-radius: 11px;
    background-color: #0cb2fb;
    transition: all ease 0.3s;
  }
  .page-p:hover {
    margin-top: 1px;
    margin-bottom: 1px;
    height: 22px;
    width: 22px;
    border-radius: 11px;
    background-color: #0cb2fb;
    transition: all ease 0.3s;
  }
  .page-p:hover {
    height: 22px;
    width: 22px;
    border-radius: 11px;
    background-color: #0cb2fb;
    transition: all ease 0.3s;
  }
  .dot {
    width: 2px;
    height: 2px;
    border: 2px;
    margin: 5px;
    background-color: rgba(255, 255, 255, 1);
    color: rgba(255, 255, 255, 0);
  }
}
</style>
