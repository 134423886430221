<template>
  <div class="home" id="home">
    <first></first>
    <div class="content">
      <side-nav></side-nav>
      <!-- <what></what> -->
      <who></who>
      <why></why>
      <partners></partners>
    </div>
    <div
      @click="toTop()"
      class="top-ball"
      :class="{ hidden: !displayFlag }"
      id="top-ball"
    >
      <img src="../assets/image/yellowball.svg" />
      <img class="top1" src="../assets/image/top1.svg" />
      <img class="top2" src="../assets/image/top2.svg" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import First from "@/components/first";
// import What from "@/components/what";
import Who from "@/components/who";
import Why from "@/components/why";
import Partners from "@/components/partners";
import SideNav from "@/components/common/side";

export default {
  name: "Home",
  components: {
    First,
    // What,
    Who,
    Why,
    Partners,
    SideNav,
  },
  data() {
    return {
      displayFlag: false,
    };
  },
  mounted() {
    document.addEventListener("scroll", this.appScroll);
  },
  beforeUnmount() {
    document.removeEventListener("scroll", this.appScroll);
  },
  methods: {
    toTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    appScroll() {
      const first = document.getElementById("first");
      const top = document.querySelector("#top-ball");
      if (!first || !top) return;

      const baseHeight = first.offsetHeight - 300;
      if (document.documentElement.scrollTop > baseHeight) {
        this.displayFlag = true;
      } else {
        this.displayFlag = false;
      }
      // const offsetY = 360;
      // if (document.documentElement.scrollTop + window.innerHeight + offsetY >= document.documentElement.offsetHeight) {
      //   // top.style.bottom = `${offsetY}px`;
      // } else {
      //   top.style.bottom = "360px";
      // }
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  .content {
    padding-top: 80px;
  }
  .top-ball {
    cursor: pointer;
    position: fixed;
    right: 100px;
    bottom: 360px;
    z-index: 10;
    transition: all ease 0.3s;
    .top1 {
      position: absolute;
      left: 50%;
      top: 35%;
      transform: translate(-50%);
    }
    .top2 {
      position: absolute;
      left: 50%;
      top: 43%;
      transform: translate(-50%);
    }
  }
  .hidden {
    opacity: 0;
    z-index: 0;
    transition: all ease 0.3s;
  }
}
</style>
