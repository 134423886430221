<template>
  <div class="foot">
    <div class="up">
      <div class="logo">
        <router-link to="/">
          <img src="../../assets/image/headlogo.svg" width="173" height="49" />
        </router-link>
      </div>
      <div class="social">
        <div class="item">
          <a href="https://github.com/polynetwork" target="_blank">
            <img src="../../assets/image/so1.svg" width="28" />
          </a>
        </div>
        <div class="item">
          <a href="https://discord.com/invite/y6MuEnq" target="_blank">
            <img src="../../assets/image/so2.svg" width="32" />
          </a>
        </div>
        <div class="item">
          <a href="https://t.me/polynetworkgroup" target="_blank">
            <img src="../../assets/image/so3.svg" width="28" />
          </a>
        </div>
        <div class="item">
          <a
            href="https://www.youtube.com/channel/UC4vFRyVgvK7RnlkkLDmp23w/featured"
            target="_blank"
          >
            <img src="../../assets/image/so4.svg" width="30" />
          </a>
        </div>
        <div class="item">
          <a href="mailto:contact@poly.network">
            <img src="../../assets/image/so5.svg" width="30" />
          </a>
        </div>
        <div class="item">
          <a href="https://twitter.com/PolyNetwork2" target="_blank">
            <img src="../../assets/image/so6.svg" width="30" />
          </a>
        </div>
        <div class="item">
          <a href="https://medium.com/@polynetwork" target="_blank">
            <img src="../../assets/image/so7.svg" width="30" />
          </a>
        </div>
      </div>
    </div>
    <div class="down">
      <span
        >© {{ new Date().getFullYear() }} PolyNetwork. All rights reserved</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.foot {
  padding: 0 80px 54px;
  display: flex;
  flex-direction: column;
  position: relative;
  .up {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 49px;
    padding-bottom: 90px;
    .logo {
      color: #fff;
    }
    .social {
      display: flex;
      align-items: center;
      .item {
        padding-right: 25px;
        cursor: pointer;
        transition: all 0.3s cubic-bezier(0.21, 0.6, 0.35, 1);
        &:hover img {
          opacity: 0.8;
        }
      }
    }
  }
  .down {
    span {
      opacity: 0.6;
      font-family: DM Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      text-align: center;

      /* 1-why poly describe */

      color: #f1f1f1;
      text-align: center;
    }
  }
}
</style>
