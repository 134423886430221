import "./assets/css/set.css";
import { createApp } from "vue";
import mitt from "mitt";
import App from "./App.vue";
import router from "./router";
const app = createApp(App);
const emitter = mitt();

import { ObserveVisibility } from "vue-observe-visibility";
app.directive("observe-visibility", {
  beforeMount: (el, binding, vnode) => {
    vnode.context = binding.instance;
    ObserveVisibility.bind(el, binding, vnode);
  },
  update: ObserveVisibility.update,
  unmounted: ObserveVisibility.unbind,
});

app.config.globalProperties.emitter = emitter;
app.use(router).mount("#app");
